import React, { useState } from 'react';
import useWindowDimensions from '../useWindowDimensions';
import { customHr } from '../../assets/images';
import useModalStore from '../StoreModal/useModalStore';

const SingleProduct = ({ prod, loop }) => {
  const [hover, setHover] = useState(false);
  const [ingredients, setIngredients] = useState(true);
  const { height, width } = useWindowDimensions();

  const scrollToRecipe = (prefix) => {
    const titleElement = document.getElementById(prefix + 'RecipeSectionTitle');
    titleElement.scrollIntoView({ behavior: 'smooth' });
  };

  const updateModalState = useModalStore((state) => state.updateModalState);
  const updateStoreList = useModalStore((state) => state.updateStoreList);

  const handleOpen = (productName) => {
    updateStoreList(productName);
    updateModalState(true);
  };

  /* const scrollToWhereToBuy = () => {
    const titleElement = document.getElementById('whereToBuyScrollSection');
    titleElement.scrollIntoView({ behavior: 'smooth' });
  }; */

  return (
    <div class="alx-contenedor-todo-producto-detalle">

        <div className="alx-contenedor-producto-detalle">
            <div className={`alx-contenedor-producto-detalle-img ${loop % 2 !== 0 ? 'alx-contenedor-producto-detalle-img-derecha' : ''}`}>
                <div class={`${prod.recipe_prefix == 'Corn' || prod.recipe_prefix == 'Tomato' ? 'alx-cintillo-new-product' : ''}`}></div>
                <img  
                  className="alx-producto-detalle-imagen-principal" 
                  src={hover ? prod.img_reverse : prod.img}
                  alt={`${prod.title} package`}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                />
                <p className="hover-over-instruction mt-5 text-center font-gothamBold text-sm font-semibold text-[#292626]">
                  {width < 578
                    ? 'Click over the image to see the reverse.'
                    : 'Hover over the image to see the reverse.'}
                </p>
            </div>
            <div className="alx-contenedor-producto-detalle-informacion">
                <div className="alx-txt-center alx-title-producto">
                  <h3
                    className="m-4 font-gothamBold text-2xl uppercase leading-[0.9] text-mf-brown-200 md:text-3xl alx-titulo-productos-principal"
                    dangerouslySetInnerHTML={{ __html: prod.formated_title }}
                    id={prod.recipe_prefix + 'ProductTitle'}
                  ></h3>
                </div>
                <img
                    src={prod.img_made_with}
                    alt="made with logo"
                    className="alx-producto-etiqueta"
                  />
                <div className="alx-contenedor-producto-detalle-descripcion">
                    <p
                      className="text-justify font-gothamBook font-semibold text-mf-brown-200"
                      dangerouslySetInnerHTML={{ __html: prod.description }}
                    />
                </div>
                <div className="alx-producto-detalle-botones">
                  <button
                    className={`alx-producto-detalle-boton m-0 p-4 font-gothamBold text-md font-semibold uppercase tracking-[-1px] text-mf-brown-200 ${
                      ingredients ? 'bg-[#efa14e]' : 'bg-transparent'
                    }`}
                    onClick={() => setIngredients(true)}
                  >
                    Ingredients
                  </button>
                  <button
                    className={`alx-producto-detalle-boton alx-boton-nutri m-0 p-4 font-gothamBold text-md font-semibold uppercase tracking-[-1px] text-mf-brown-200 ${
                      ingredients ? 'bg-transparent' : 'bg-[#efa14e]'
                    }`}
                    onClick={() => setIngredients(false)}
                  >
                    Nutrition facts
                  </button>
                  <button
                      className="alx-producto-detalle-boton m-0 p-4 font-gothamBold text-md font-semibold uppercase tracking-[-1px] text-mf-brown-200"
                      onClick={() => scrollToRecipe(prod.recipe_prefix)}
                    >
                      Lets cook
                    </button>

                    {(prod.recipe_prefix != 'Corn' && prod.recipe_prefix != 'Tomato') ? (

                    <button
                      className="alx-producto-detalle-boton m-0 p-4 font-gothamBold text-md font-semibold uppercase tracking-[-1px] text-mf-brown-200"
                      onClick={() => handleOpen(prod.recipe_prefix)}
                    >
                      Buy now
                    </button>
                    ) : (<button
                      className="alx-producto-detalle-boton m-0 p-4 font-gothamBold text-md font-semibold uppercase tracking-[-1px] text-mf-brown-200"
                    >
                      SOON AVAILABLE
                    </button>)}
                </div>
                <div className="">
                  {ingredients ? (
                    <p
                      className="brown-custom text-justify font-gothamUltra font-bold text-mf-brown-200 xl:pt-4 xl:pb-16"
                      dangerouslySetInnerHTML={{ __html: prod.ingredients }}
                    ></p>
                  ) : (
                    <div className={`alx-porducto-detalle-tabla ${loop % 2 !== 0 ? 'alx-porducto-detalle-tabla-derecha' : ''}`}>
                    <div className="alx-porducto-detalle-tabla-int">
                    <img
                      src={prod.nutri_img}
                      className="m-auto alx-producto-detalle-tabla-imagen"
                      alt="nutritional info"
                    />
                    
                    </div>
                    </div>
                  )}
                </div>
            </div>
            <div class="alx-base-clear"></div>
            
        </div>

        
        <img src={customHr} className="m-auto alx-bar-separador" alt="" />












    </div>
  );
};

export default SingleProduct;
