import React from 'react';
import { ProductPouches, ProductPouchesConRices } from '../assets/images';

const Hero = () => {
  return (
    <section className="relative overflow-hidden bg-gradient-to-b from-mf-orange-300 via-mf-orange-400">
      <div className="container-fluid relative z-20 mx-auto max-w-screen-xlNO px-5 md:pt-16">
        <div className="flex flex-col justify-center">
          <div className="mx-auto my-10 min-h-[150px] w-full bg-heroTitle bg-contain bg-top bg-no-repeat md:my-6 md:min-h-[230px] lg:max-w-[600px] alx-hero-banner-txt alx-hero-title">
            <h2 className="sr-only">México is just 2 minutes away</h2>
          </div>
          <div className="mx-auto" id="alx-home-banner-contenedor">
            
            <div id="alx-home-banner-contenedor-int">
            <img
              src={ProductPouchesConRices}
              alt="Isadora Porduct Pouches"
              className="mx-auto max-w-[100%] md:max-w-[100%]" 
              id="alx-banner-img"
            />
            <div id="alx-home-label-new-product"></div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="whiteBlock"
        className="absolute bottom-0 -mt-[100px] min-h-[100px] w-[110%] translate-x-[-5%] rounded-t-hero-sm bg-white md:-mt-[200px] md:min-h-[200px] md:rounded-t-hero-lg lg:-mt-[300px] lg:min-h-[300px]"
      ></div>
    </section>
  );
};

export default Hero;
