import { Fragment, forwardRef } from 'react';
import SingleProduct from './SingleProduct';

import {
  productBarbacoBeef,
  productPolloMole,
  productPork,
  madeWithChicken,
  madeWithBeef,
  madeWithPork,
  beefBack,
  chickenBack,
  porkBack,
  nutrition_facts_Beef,
  nutrition_facts_chicken,
  nutrition_facts_pork, 
  productRiceWithCorn, 
  productRiceWithTomato, 
  productRiceWithCornBack, 
  productRiceWithTomatoBack, 
  nutrition_facts_rice_corn, 
  nutrition_facts_rice_tomato, 
  riceCornSabor, 
  riceTomatoSabor
} from '../assets/images';
import React from 'react';



const productsList = [
  {
    title: 'barbacoa mexican style seasoned beef',
    formated_title:
      "<strong class='font-gothamUltra font-bold'>BARBACOA</strong><br />MEXICAN STYLE SEASONED BEEF",
    description: `Barbacoa is a dish originating from the mexican from the Mexican Tlaxcalteca culture. <strong class='font-gothamUltra font-bold'> Traditionally, it was prepared by wrapping the meat with roasted maguey leaves and slow cooking in an earth oven.</strong>`,
    ingredients: `Beef, water, tomato puree, vinegar, salt, onion powder, contains 1% or less of garlic powder, oregano, ground cumin, natural flavouring and paprika.`,
    img: productBarbacoBeef,
    img_reverse: beefBack,
    nutri_img: nutrition_facts_Beef,
    img_made_with: madeWithBeef,
    recipe_prefix: 'Barbacoa',
  },
  {
    title: 'pollo con mole chicken with mole sauce',
    formated_title:
      "<strong class='font-gothamUltra font-bold'>pollo con mole</strong><br />chicken <small>with</small> mole sauce",
    description: `The word “Mole” comes from the Nahuatl Language and translates as “stew” or “sauce”. <strong class="font-gothamUltra font-bold">It is prepared with a variety of chili peppers, aromatic spices, oilseeds, nuts, traditional Mexican chocolate, as well as other ingredients.</strong> This incredible chicken dish has been inspired over three centuries and is a Mexican favorite!`,
    ingredients: `Water, chicken meat, mole powder (corn flour, mulato pepper, ancho pepper, pasilla pepper, peanut, onion powder, garlic powder, cinnamon, cloves, ground black pepper, ground anise seed), brown sugar, chocolate (cocoa, sugar, cinnamon, soy lecithin), sesame seed and salt. <br /><br />CONTAINS PEANUTS, SOY, SESAME SEED.`,
    img: productPolloMole,
    img_reverse: chickenBack,
    nutri_img: nutrition_facts_chicken,
    img_made_with: madeWithChicken,
    recipe_prefix: 'Mole',
  },
  {
    title: 'chile verde pork in green salsa',
    formated_title:
      "<strong class='font-gothamUltra'>Cerdo chile verde</strong><br />pork in green salsa",
    description: `Inspired by the colors of the countryside and the cultural influences from different regions of México, <strong class="font-gothamUltra font-bold">our tender pork meat is cooked in green salsa prepared from tomatillos, serrano pepper, coriander and other seasonings.</strong> It is undoubtedly a popular dish worthy of our Mexican heritage.`,
    ingredients: `Pork, tomatillo, water, lard, onion, salt, serrano pepper, contains 1% or less of dehydrated coriander, garlic powder, sugar and natural flavorings.`,
    img: productPork,
    img_reverse: porkBack,
    nutri_img: nutrition_facts_pork,
    img_made_with: madeWithPork,
    recipe_prefix: 'Pork',
  },
  {
    title: 'mexican rice with corn',
    formated_title:
      "MEXICAN RICE<br /><strong class='font-gothamUltra'>WITH CORN</strong>",
    description: `<strong class="font-gothamUltra font-bold">This rice has a characteristic sweet corn flavor and a light chicken broth flavor.</strong> One of the product’s main characteristics is its appearance and texture; the cooked grain is loose but has a firm texture, and yellow corn is present.`,
    ingredients: `Water, extra long grain rice, corn, natural flavoring, salt and vegetable oil  (corn oil and/or canola oil). <br/><br/>MAY CONTAIN SOY AND SESAME SEED.`,
    img: productRiceWithCorn,
    img_reverse: productRiceWithCornBack,
    nutri_img: nutrition_facts_rice_corn,
    img_made_with: riceCornSabor,
    recipe_prefix: 'Corn',
  },
  {
    title: 'mexican rice with tomato',
    formated_title:
      "MEXICAN RICE<br /><strong class='font-gothamUltra'>WITH TOMATO</strong>",
    description: `<strong class="font-gothamUltra font-bold">This rice is flavored with a tomato base, seasoned with onion and garlic, and rounded off with chicken broth.</strong> The cooked grains are reddish-orange in color and firm in texture.`,
    ingredients: `Water, extra long grain rice, tomato paste, salt, citrus blend (citric acid and ascorbic acid), vegetable oil (corn oil and/or canola oil), natural fl­avoring, natural colors (paprika oleoresin, lycopene) and spices. <br/><br/>MAY CONTAIN SOY AND SESAME SEED.`,
    img: productRiceWithTomato,
    img_reverse: productRiceWithTomatoBack,
    nutri_img: nutrition_facts_rice_tomato,
    img_made_with: riceTomatoSabor,
    recipe_prefix: 'Tomato',
  },
];

const prodElements = [];

productsList.map((prod, index) =>
  prodElements.push(
    <Fragment key={index}>
      <SingleProduct prod={prod} loop={index} />
      {index < 2 && (
        <div/>
      )}
    </Fragment>
  )
);

const Products = forwardRef((props, ref) => {
  return (
    <section
      className="bg-recipesTexture bg-contain bg-center bg-repeat alx-seccion-productos-detalles"
      ref={ref}
    >
      {prodElements}
    </section>
  );
});

export default Products;
