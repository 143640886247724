import { forwardRef } from 'react';
import {
  MoleBowlPack,
  BarbacoaBowlPack,
  PorkBowlPack,
  IngredientsIcons, 
  RiceSpec, 
  RiceCornBowlPack, 
  RiceTomatoBowlPack, 
} from '../assets/images';

const AboutUs = forwardRef((props, ref) => {
  
  /*document.getElementById('alx-anterior').addEventListener('click', function() {
    alert('hola');
  });
  document.getElementById('alx-siguiente').addEventListener('click', function() {
    alert('hola');
  });*/


  const flechaIzquierda = () => {
    let div = document.getElementById('alx-muestra-producto-contenedor-padre');
    div.style.marginLeft = '0%';
  };
  const flechaDerecha = () => {
    let div = document.getElementById('alx-muestra-producto-contenedor-padre');
    div.style.marginLeft = '-50%';
    
  };

  return (
    <div
      className="relative overflow-x-hidden md:-mb-12 lg:-mt-[85px] lg:pt-[85px]"
      id="about-us-section-bookmark"
    >
      <section className="bg-mf-orange-200" ref={ref}>

        <div id="alx-decora-arroz"></div>
        <div id="alx-decora-barbacoa"></div>



        <div className="container mx-auto max-w-screen-xl px-5" id="alx-centro-txt-the-authentic">
          <div className="flex flex-row justify-center">
            <div className="flex flex-col  justify-center pt-10 text-white md:pt-16 md:pb-10 lg:w-[100%] lg:pb-0">
              <div className="mx-auto lg:max-w-[650px]">
                <h2 className="mb-7 text-center font-gothamBold text-2xl uppercase drop-shadow-[2px_3px_0_#be3200] md:text-4xl alx-about-us-title-the-authentic-gral">
                  
                  <span className="custom-fs-4-2r the-auth-m font-futuraBold text-4xl md:text-6xl alx-about-us-title-the-authentic">
                    The authentic
                  </span>
                  <br/>

                  <span id="imported-from" className="font-futuraBold alx-about-us-title-the-authentic-from">imported from </span>
                  <span
                    className="font-reyesBlack text-3xl md:text-5xl alx-about-us-title-the-authentic-mexico"
                    id="imp-mexico"
                  >México</span><br/>


                  <span id="ready-to-eat">ready to eat <span className="text-mf-yellow-200">food line</span>
                  </span>

                </h2>

                <p className="alx-about-us-parrafo font-s-1-8 margin-x-2-mobile text-center font-alternate3 text-xl text-white md:text-3xl  ">
                  <span className="font-gothamBold">Isadora
                  <small className="small-trademark font-futuraBold">
                    &#174;
                  </small> </span>


                  ready-to-eat rice pouches include Mexican rice with tomato and Mexican rice with corn. Crafted from authentic Mexican recipes and premium ingredients, they come in flexible pouches using the latest packaging technology to bring the vibrant flavors of México straight to the table. Each pouch is ready to serve after 90 seconds in the microwave. Enjoy <span class="font-bold">THE AUTHENTIC TASTE OF MEXICAN FOOD</span> with every bite.
                  


                 
                </p>
              </div>
            </div>
            
          </div>
          <div className="flex flex-col justify-center pb-36 alx-aobut-us-contenedor-best-quaily">
            <div className="mx-auto h-16 w-48 bg-whiteLine bg-contain bg-center bg-no-repeat"></div>
            <h3 className="lineHeight-09 mt-5 mb-14 text-center font-gothamMedium text-3xl uppercase text-white drop-shadow-[2px_3px_0_#be3200] md:mb-20 md:text-6xl">
              <span className="font-futuraBold text-4xl md:text-6xl alx-best-quality-title">
                Best Quality
              </span>
              <br></br>
              <span className="text-alt-yellow-new-100 alx-ingredients-title font-gothamBold">Ingredients</span>
            </h3>
          </div>
        </div>
        

      </section>
      

      <div id="alx-muestra-producto-contenedor-todo">


          <div class="alx-flecha alx-anterior" id="alx-anterior" onClick={flechaIzquierda}></div>
          <div class="alx-flecha alx-siguiente" id="alx-siguiente" onClick={flechaDerecha}></div>
        


      <div id="alx-muestra-producto-contenedor-padre">
      <div className="container" id="alx-muestra-producto-contenedor">
            <div className="alx-muestra-producto">
                <img
                  src={MoleBowlPack}
                  alt="Isadora Mole Pouch"
                  className=""
                />
                <h4 className="text-center font-gothamBold text-3xl uppercase text-mf-violet-200 lg:text-4xl alx-about-us-nombre-prod">
                  &bull; Mole &bull;
                </h4>
            </div>
            <div className="alx-muestra-producto">
                 <img
                  src={BarbacoaBowlPack}
                  alt="Isadora Barbacoa Pouch"
                  className=""
                />
                <h4 className="text-center font-gothamBold text-3xl uppercase text-mf-red-100 lg:text-4xl alx-about-us-nombre-prod">
                  &bull; Barbacoa &bull;
                </h4>
            </div>
            <div className="alx-muestra-producto">
                 <img
                    src={PorkBowlPack}
                    alt="Isadora Pork Chile Verde Pouch"
                    className=""
                  />
                  <h4 className="text-center font-gothamBold text-3xl uppercase text-mf-green-200 lg:text-4xl alx-about-us-nombre-prod">
                    &bull; Pork &bull;
                  </h4>
            </div>
      </div>
      <div id="alx-spec-1">
        <div id="alx-spec-1-mobile">
          <div id="alx-spec-1-mobile-1"></div>
          <div id="alx-spec-1-mobile-2"></div>
          <div id="alx-spec-1-mobile-3"></div>
        </div>
        <div className="container mx-auto max-w-screen-xl px-5 md:flex alx-spec-mobile-ocultar">
          <img
            src={IngredientsIcons}
            alt="Our ingredients"
            className="mx-auto w-auto"
          />
        </div>
      </div>
      </div>


      <div id="alx-muestra-producto-contenedor-2-padre">
        




      <div className="container" id="alx-muestra-producto-contenedor-2">
            <div className="alx-muestra-producto">
                <img
                  src={RiceCornBowlPack}
                  alt="Isadora Rice with Corn Pouch"
                  className=""
                />
                <h4 className="text-center font-gothamBold text-3xl uppercase text-mf-violet-200 lg:text-4xl alx-about-us-nombre-prod alx-color-corn">
                  &bull; CORN &bull;
                </h4>
            </div>
            <div className="alx-muestra-producto">
                 <img
                  src={RiceTomatoBowlPack}
                  alt="Isadora Rice with Tomato Pouch"
                  className=""
                />
                <h4 className="text-center font-gothamBold text-3xl uppercase text-mf-red-100 lg:text-4xl alx-about-us-nombre-prod alx-color-tomato">
                  &bull; TOMATO &bull;
                </h4>
            </div>
      </div>
      <div id="alx-spec-2">
        <div id="alx-spec-2-mobile">
          <div id="alx-spec-2-mobile-1"></div>
          <div id="alx-spec-2-mobile-2"></div>
        </div>
        <div className="container mx-auto max-w-screen-xl px-5 md:flex alx-spec-mobile-ocultar-2">
          <img
            src={RiceSpec}
            alt="Our ingredients"
            className="mx-auto w-auto"
          />
        </div>
      </div>
      </div>

      </div>
      




       
    </div>
  );
});

export default AboutUs;
