import { SocialMediaNav } from './';

const FollowUs = () => {
  return (
    <section className='bg-mf-orange-200 pt-12 pb-10'>
      <div className='container mx-auto max-w-screen-xl px-5'>
        <div className='flex-col justify-center'>
          <div className='flex flex-col-reverse justify-center'>
            <h2 className='text-center font-futuraBold text-3xl uppercase tracking-wider text-white md:text-5xl alx-contact-titulo'>
              Follow Us
            </h2>
            <p className='mb-8 text-center font-alternate3 text-xl text-white md:text-4xl'>
              Did you find everything you were looking for? Do you need any
              assistance?<br></br>
              Visit our social media profile.
            </p>
          </div>
          <div className='mx-auto h-10 w-48 bg-whiteLine bg-contain bg-center bg-no-repeat'></div>
          <div className='mt-6 min-h-[80px] text-center'>
            <SocialMediaNav />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FollowUs;
