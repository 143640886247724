import {
  Frys,
  Bakers,
  CityMarket,
  Dillons,
  FredMeyer,
  Gerbes,
  JayC,
  KingSoopers,
  Smiths,
  Kroger,
  MetroMarket,
  Payless,
  QFC,
  PickNSave,
  Marianos,
  Ralphs,
} from '../../assets/images';

const stores = {
  frys: {
    name: "Fry's",
    logo: Frys,
    links: {
      mole: 'https://www.frysfood.com/p/isadora-gluten-free-pollo-con-mole/0078162400909?fulfillment=SHIP&searchType=default_search',
      barbacoa:
        'https://www.frysfood.com/p/isadora-mexican-style-barbaoca/0078162400908?fulfillment=SHIP&searchType=default_search',
      pork: 'https://www.frysfood.com/p/isadora-pork-chile-verde/0078162400910?fulfillment=SHIP&searchType=default_search',
      /*tomato: 'https://www.isadoramexicanfood.com/', 
      corn: 'https://www.isadoramexicanfood.com/',*/
    },
    stockMole: true,
    stockBarbacoa: true,
    stockPork: true,
  },
  fred_meyer: {
    name: 'Fred Meyer',
    logo: FredMeyer,
    links: {
      mole: 'https://www.fredmeyer.com/p/isadora-gluten-free-pollo-con-mole/0078162400909?fulfillment=SHIP&searchType=default_search',
      barbacoa:
        'https://www.fredmeyer.com/p/isadora-mexican-style-barbaoca/0078162400908?fulfillment=SHIP&searchType=default_search',
      pork: 'https://www.fredmeyer.com/p/isadora-pork-chile-verde/0078162400910?fulfillment=SHIP&searchType=default_search',
    },
    stockMole: true,
    stockBarbacoa: true,
    stockPork: true,
  },
  smiths: {
    name: "Smith's",
    logo: Smiths,
    links: {
      mole: 'https://www.smithsfoodanddrug.com/p/isadora-gluten-free-pollo-con-mole/0078162400909?fulfillment=IN_STORE&searchType=default_search',
      barbacoa:
        'https://www.smithsfoodanddrug.com/p/isadora-mexican-style-barbaoca/0078162400908?fulfillment=IN_STORE&searchType=default_search',
      pork: 'https://www.smithsfoodanddrug.com/p/isadora-pork-chile-verde/0078162400910?fulfillment=IN_STORE&searchType=default_search',
    },
    stockMole: true,
    stockBarbacoa: true,
    stockPork: true,
  },
  city_market: {
    name: 'City Market',
    logo: CityMarket,
    links: {
      mole: 'https://www.citymarket.com/p/isadora-gluten-free-pollo-con-mole/0078162400909?fulfillment=SHIP&searchType=default_search',
      barbacoa:
        'https://www.citymarket.com/p/isadora-mexican-style-barbaoca/0078162400908?fulfillment=SHIP&searchType=default_search',
      pork: 'https://www.citymarket.com/p/isadora-pork-chile-verde/0078162400910?fulfillment=SHIP&searchType=default_search',
    },
    stockMole: true,
    stockBarbacoa: true,
    stockPork: true,
  },
  kroger: {
    name: 'Kroger',
    logo: Kroger,
    links: {
      mole: 'https://www.kroger.com/p/isadora-gluten-free-pollo-con-mole/0078162400909?fulfillment=SHIP&searchType=default_search',
      barbacoa:
        'https://www.kroger.com/p/isadora-mexican-style-barbaoca/0078162400908?fulfillment=SHIP&searchType=default_search',
      pork: 'https://www.kroger.com/p/isadora-pork-chile-verde/0078162400910?fulfillment=SHIP&searchType=default_search',
    },
    stockMole: true,
    stockBarbacoa: true,
    stockPork: true,
  },
  jayc: {
    name: 'Jay C',
    logo: JayC,
    links: {
      mole: 'https://www.jaycfoods.com/p/isadora-gluten-free-pollo-con-mole/0078162400909?fulfillment=SHIP&searchType=default_search',
      barbacoa:
        'https://www.jaycfoods.com/p/isadora-mexican-style-barbaoca/0078162400908?fulfillment=SHIP&searchType=default_search',
      pork: 'https://www.jaycfoods.com/p/isadora-pork-chile-verde/0078162400910?fulfillment=SHIP&searchType=default_search',
    },
    stockMole: true,
    stockBarbacoa: true,
    stockPork: true,
  },
  metro_market: {
    name: 'Metro Market',
    logo: MetroMarket,
    links: {
      mole: 'https://www.metromarket.net/p/isadora-gluten-free-pollo-con-mole/0078162400909?fulfillment=SHIP&searchType=default_search',
      barbacoa:
        'https://www.metromarket.net/p/isadora-mexican-style-barbaoca/0078162400908?fulfillment=SHIP&searchType=default_search',
      pork: 'https://www.metromarket.net/p/isadora-pork-chile-verde/0078162400910?fulfillment=SHIP&searchType=default_search',
    },
    stockMole: true,
    stockBarbacoa: true,
    stockPork: true,
  },
  pay_less: {
    name: 'Pay Less',
    logo: Payless,
    links: {
      mole: 'https://www.pay-less.com/p/isadora-gluten-free-pollo-con-mole/0078162400909?fulfillment=SHIP&searchType=default_search',
      barbacoa:
        'https://www.pay-less.com/p/isadora-mexican-style-barbaoca/0078162400908?fulfillment=SHIP&searchType=default_search',
      pork: 'https://www.pay-less.com/p/isadora-pork-chile-verde/0078162400910?fulfillment=SHIP&searchType=default_search',
    },
    stockMole: true,
    stockBarbacoa: true,
    stockPork: true,
  },
  qfc: {
    name: 'QFC',
    logo: QFC,
    links: {
      mole: 'https://www.qfc.com/p/isadora-gluten-free-pollo-con-mole/0078162400909?fulfillment=SHIP&searchType=default_search',
      barbacoa:
        'https://www.qfc.com/p/isadora-mexican-style-barbaoca/0078162400908?fulfillment=SHIP&searchType=default_search',
      pork: 'https://www.qfc.com/p/isadora-pork-chile-verde/0078162400910?fulfillment=SHIP&searchType=default_search',
    },
    stockMole: true,
    stockBarbacoa: true,
    stockPork: true,
  },
  gerbes: {
    //TODO: Actualizar links de Gerbes
    name: 'Gerbes',
    logo: Gerbes,
    links: {
      mole: 'https://www.frysfood.com/p/isadora-gluten-free-pollo-con-mole/0078162400909?fulfillment=SHIP&searchType=default_search',
      barbacoa:
        'https://www.frysfood.com/p/isadora-mexican-style-barbaoca/0078162400908?fulfillment=SHIP&searchType=default_search',
      pork: 'https://www.frysfood.com/p/isadora-pork-chile-verde/0078162400910?fulfillment=SHIP&searchType=default_search',
    },
    stockMole: true,
    stockBarbacoa: true,
    stockPork: true,
  },
  pick_n_save: {
    name: "Pick'N Save",
    logo: PickNSave,
    links: {
      mole: 'https://www.picknsave.com/p/isadora-gluten-free-pollo-con-mole/0078162400909?fulfillment=SHIP&searchType=default_search',
      barbacoa:
        'https://www.picknsave.com/p/isadora-mexican-style-barbaoca/0078162400908?fulfillment=SHIP&searchType=default_search',
      pork: 'https://www.picknsave.com/p/isadora-pork-chile-verde/0078162400910?fulfillment=SHIP&searchType=default_search',
    },
    stockMole: true,
    stockBarbacoa: true,
    stockPork: true,
  },
  dillons: {
    name: 'Dillons',
    logo: Dillons,
    links: {
      mole: 'https://www.dillons.com/p/isadora-gluten-free-pollo-con-mole/0078162400909?fulfillment=SHIP&searchType=default_search',
      barbacoa:
        'https://www.dillons.com/p/isadora-mexican-style-barbaoca/0078162400908?fulfillment=SHIP&searchType=default_search',
      pork: 'https://www.dillons.com/p/isadora-pork-chile-verde/0078162400910?fulfillment=SHIP&searchType=default_search',
    },
    stockMole: true,
    stockBarbacoa: true,
    stockPork: true,
  },
  bakers: {
    name: 'Bakers',
    logo: Bakers,
    links: {
      mole: 'https://www.bakersplus.com/p/isadora-gluten-free-pollo-con-mole/0078162400909?fulfillment=SHIP&searchType=default_search',
      barbacoa:
        'https://www.bakersplus.com/p/isadora-mexican-style-barbaoca/0078162400908?fulfillment=SHIP&searchType=default_search',
      pork: 'https://www.bakersplus.com/p/isadora-pork-chile-verde/0078162400910?fulfillment=SHIP&searchType=default_search',
    },
    stockMole: true,
    stockBarbacoa: true,
    stockPork: true,
  },
  marianos: {
    name: "Mariano's",
    logo: Marianos,
    links: {
      mole: 'https://www.marianos.com/p/isadora-gluten-free-pollo-con-mole/0078162400909?fulfillment=SHIP&searchType=default_search',
      barbacoa:
        'https://www.marianos.com/p/isadora-mexican-style-barbaoca/0078162400908?fulfillment=SHIP&searchType=default_search',
      pork: 'https://www.marianos.com/p/isadora-pork-chile-verde/0078162400910?fulfillment=SHIP&searchType=default_search',
    },
    stockMole: true,
    stockBarbacoa: true,
    stockPork: true,
  },
  ralphs: {
    //TODO: Actualizar links de Ralphs
    name: "Ralph's",
    logo: Ralphs,
    links: {
      mole: 'https://www.frysfood.com/p/isadora-gluten-free-pollo-con-mole/0078162400909?fulfillment=SHIP&searchType=default_search',
      barbacoa:
        'https://www.frysfood.com/p/isadora-mexican-style-barbaoca/0078162400908?fulfillment=SHIP&searchType=default_search',
      pork: 'https://www.frysfood.com/p/isadora-pork-chile-verde/0078162400910?fulfillment=SHIP&searchType=default_search',
    },
    stockMole: true,
    stockBarbacoa: true,
    stockPork: true,
  },
  king_soopers: {
    name: 'King Soopers',
    logo: KingSoopers,
    links: {
      mole: 'https://www.kingsoopers.com/p/isadora-gluten-free-pollo-con-mole/0078162400909?fulfillment=SHIP&searchType=default_search',
      barbacoa:
        'https://www.kingsoopers.com/p/isadora-mexican-style-barbaoca/0078162400908?fulfillment=SHIP&searchType=default_search',
      pork: 'https://www.kingsoopers.com/p/isadora-pork-chile-verde/0078162400910?fulfillment=SHIP&searchType=default_search',
    },
    stockMole: true,
    stockBarbacoa: true,
    stockPork: true,
  },
};

export default stores;
